<template>
  <div class="questionnaire">
    <div class="menus">
      <el-button type="success" class="btn" @click="toHandle('create')">
        新建问卷
      </el-button>
      <a
        v-for="menu in menus"
        :key="menu.name"
        :class="{ active: active === menu.name }"
        class="menu-item"
        href="javascript:;"
        @click="checkoutMenu(menu)"
      >
        {{ menu.label }}
      </a>
    </div>
    <div class="content">
      <el-row :gutter="20">
        <el-col v-for="card in questionList" :key="card.id" :span="6">
          <div class="card">
            <div class="top">
              <div class="status">
                {{ tagTextMap[card.status] }}
              </div>
            </div>
            <div class="bottom">
              <div class="bottom-title">
                {{ card.title }}
              </div>
              <div class="bottom-handle">
                <div class="has-status" v-if="false">
                  <div class="count">0份</div>
                  <div class="id">ID {{ card.id }}</div>
                </div>
                <div class="not-status" v-else>
                  <el-button
                    v-if="card.status === 1 || card.status === 99"
                    type="text"
                    icon="el-icon-video-play"
                    @click="startOrStop(card.id)"
                  >
                    开始
                  </el-button>
                  <el-button
                    v-else
                    type="text"
                    icon="el-icon-video-pause"
                    @click="startOrStop(card.id)"
                  >
                    暂停
                  </el-button>
                  <el-button
                    type="text"
                    icon="el-icon-edit"
                    @click="toHandle('edit', card.id, card.title)"
                  >
                    编辑
                  </el-button>
                  <el-button
                    type="text"
                    icon="el-icon-s-data"
                    @click="toStatustucs('survey', card.id, card.title)"
                  >
                    统计
                  </el-button>
                  <el-dropdown @command="moreHandle($event, card)">
                    <el-button type="text" icon="el-icon-more"></el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        command="copy"
                        v-clipboard:copy="linkFn(card.id)"
                        v-clipboard:success="onCopy"
                      >
                        <span style="margin-right: 40px">
                          ID: {{ card.id }}
                        </span>
                        <i
                          class="el-icon-copy-document"
                          @click="questionnaireCopyM(card.id)"
                        ></i>
                      </el-dropdown-item>
                      <el-dropdown-item
                        v-for="(item, index) in dropdown"
                        :key="index"
                        :divided="index === 0"
                        :command="item.value"
                      >
                        {{ item.label }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import apis from "../../apis";
import Cookies from "js-cookie";

export default {
  name: "questionnaire",
  data() {
    return {
      tagTextMap: {
        1: "暂存",
        10: "答题中",
        99: "暂停",
      },
      questionList: [],
      active: 1,
      menus: [
        { label: "内部问卷", name: 1 },
        { label: "外部问卷", name: 2 },
      ],
      dropdown: [
        // { label: '导出', value: '' },
        // { label: '打印', value: '' },
        // { label: '复制', value: '' },
        // { label: '设置', value: '' },
        { label: "投放问卷", value: "push" },
        { label: "被评价人消息推送", value: "messagePush" },
        { label: "重命名", value: "rename" },
        { label: "删除", value: "delete" },
      ],
    };
  },
  methods: {
    checkoutMenu(menu) {
      this.active = menu.name;
      this.getData();
    },
    linkFn(id) {
      return id.toString();
    },
    onCopy() {
      this.$message.success("复制成功");
    },
    questionnaireCopyM(id) {
      apis
        .questionnaireCopy({
          id,
        })
        .then((res) => {
          if (res.code === 200) {
            this.getData();
          }
        });
    },
    moreHandle(command, card) {
      switch (command) {
        case "push":
          apis
            .questionSend({
              id: card.id,
            })
            .then(() => {
              this.$message({
                type: "success",
                message: "投放成功!",
              });
              this.getData();
            });
          break;
        case "messagePush":
          apis.
          questionMessagePush({
            questionnaireId: card.id,
          }).then(()=>{
            this.$message({
                type: "success",
                message: "推送成功!",
              });
              this.getData();
          });
          break;
        case "rename":
          this.$prompt("请输入标题")
            .then(({ value }) => {
              if (value) {
                apis
                  .questionRename({
                    title: value,
                    id: card.id,
                  })
                  .then(() => {
                    this.$message({
                      type: "success",
                      message: "修改成功!",
                    });
                    this.getData();
                  });
              }
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "取消输入",
              });
            });
          break;
        case "delete":
          this.$confirm("是否确认删除?")
            .then(() => {
              apis
                .questionDeleteByBin({
                  id: card.id,
                })
                .then(() => {
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                  this.getData();
                });
            })
            .catch(() => {
              this.$message({
                type: "info",
                message: "已取消删除",
              });
            });
          break;
      }
    },
    startOrStop(id) {
      apis.questionStopOrStart({ id }).then(() => {
        this.$message.success("操作成功");
        this.getData();
      });
    },
    toHandle(type, id, title) {
      Cookies.set("title", title);
      const query = {
        type,
        pos: this.active,
      };
      if (id) {
        query.id = id;
      }
      window.localStorage.questionId = "";
      this.$router.push({
        name: "q-handle",
        query,
      });
    },
    toStatustucs(type, id, title) {
      Cookies.set("title", title);
      const query = {
        type,
        pos: this.active,
      };
      if (id) {
        query.id = id;
        localStorage.setItem("id", id);
      }
      this.$router.push({
        name: "report",
        query,
      });
    },
    getData() {
      apis
        .questionQueryByQuestionnaireId({ type: this.active,pageSize:100 })
        .then(({ data }) => {
          this.questionList = data || [];
        });
    },
  },
  mounted() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.questionnaire {
  display: flex;

  .btn {
    margin-bottom: 20px;
    width: 100%;
    background: linear-gradient(311deg, #489e6c 0%, #52b079 95%);
    border: none;
    height: 25px;
    font-size: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .menus {
    padding: 20px 18px;
    width: 190px;
    background: #fff;

    .menu-item {
      display: block;
      text-decoration: none;
      height: 30px;
      width: 100%;
      border-radius: 4px 4px 4px 4px;
      line-height: 30px;
      text-align: center;
      font-size: 10px;
      color: #3d3d3d;

      &.active {
        background: rgba(240, 255, 243, 0.7);
        color: #008755;
      }
    }
  }
  .content {
    overflow: auto;
    padding: 20px 14px;
    flex: 1;
    height: calc(100vh - 48px);
  }

  .card {
    margin-bottom: 20px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 10px 0px #f2f2f2;
    border-radius: 4px 4px 4px 4px;
    overflow: hidden;

    .top {
      position: relative;
      height: 122px;
      background: url("~@/assets/question-bg.png");
      background-size: 100% 100%;
    }

    .bottom {
      padding: 9px 12px 4px;
      background: #fff;
    }

    .status {
      position: absolute;
      top: 10px;
      left: 15px;
      padding: 4px 6px;
      text-align: center;
      border-radius: 4px 4px 4px 4px;
      border: 1px solid #489e6c;
      font-size: 11px;
      color: #489e6c;
    }

    .bottom-title {
      margin-bottom: 4px;
      font-size: 10px;
      color: #3d3d3d;
    }

    .has-status,
    .not-status {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .not-status {
      white-space: nowrap;
      overflow: auto;
    }

    .has-status {
      font-size: 13px;
      color: #999999;
    }
  }
}
</style>
